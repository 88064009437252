<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-3 d-flex align-items-center" style="min-height: 40px;">
            <div>
			    <h3 class="card-title font-weight-bolder text-dark">Cisco Umbrella - Top Destinations {{title}}</h3>
            </div>
		</div>
		<div class="card-body px-5 pt-0">
			<ITCSpinner :loaded="loaded.chart">
				<div class="row mb-4">
					<div class="col-md-6 pr-2">
						<div class="graph-container">
							<h4 class="side-title pl-4">Allowed Request Count</h4>
							<apexchart
								type="donut"
								:height="height"
								:options="allowed"
								:series="allowed.series"
							/>
						</div>
					</div>
					<div class="col-md-6 pl-2">
						<div class="graph-container">
							<h4 class="side-title">Blocked Request Count</h4>
							<apexchart type="donut" :height="height" :options="blocked" :series="blocked.series" />
						</div>
					</div>
				</div>
				<div class="pull-right pl-4"><span>* Top destinations for the past week.</span></div>	
			</ITCSpinner>
		</div>

		<div v-if="widgetProps.ciscoumbrella.activity===true">
			<div class="card-header border-0 pt-3 pb-3 d-flex align-items-center" style="min-height: 40px;">
				<div>
					<h4 class="card-title mb-0 font-weight-bolder text-dark">Cisco Umbrella {{title}}</h4>
					<h4 class="side-title pt-3 mb-0">Recent Activity</h4>
				</div>
			</div>
			<div class="card-body pt-2 pb-0" style="height: 500px;">
				<ITCSpinner :loaded="loaded.list">
					<b-table :items="filteredActivity" :fields="fields" :current-page="currentPage" :per-page="perPage" show-empty thead-class="hidden-header">
						<template slot="top-row" slot-scope="{ fields }">
							<td :key="'domain'">
								<b-form-input v-model="filters['domain']" :placeholder="fields[0].search" @change="getActivity"></b-form-input>
							</td>
							<td></td>
							<td :key="'action'">
								<b-form-select v-model="filters['action']" :options="filterActionOptions" @change="getActivity"></b-form-select>
								<!-- <input v-model="filters['action']" @change="getActivity"> -->
							</td>
						</template>

						<template #cell(domain)="data">
							<div style="min-width:93px;">
								{{ data.value }}
							</div>
						</template>

						<template #cell(externalip)="data">
								{{ data.value }}
						</template>

						<template #cell(verdict)="data">
							<span :style="[data.value==='blocked' ? {'color':'red'} : {'color':'green'} ]">{{data.value}}</span>
						</template>

						<template #cell(timestamp)="data">
							{{timestampToDate(data.value)}}
						</template>
					</b-table>
				</ITCSpinner>
			</div>
			<div class="footer">
				<span>{{ filteredActivity.length }} results</span>
				<span>
					<b-pagination class="pagination" v-model="currentPage" :total-rows="filteredActivity.length" :per-page="perPage" />
				</span>
			</div>
		</div>
		
	</div>
</template>

<script>
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';

export default {
    name: 'CiscoUmbrella',
	props: ['id', 'name', 'widgetProps', 'source'],
	data() {
		return {
            loaded: {
				chart: false,
				list: false
			},
            title: '',
			sitesCount: 0,
			activity: [],
			searchText: '',
			currentPage: 1,
			perPage: 10,
			filters: {
				domain: '',
				// externalip: '',
				action: '',
				// time: ''
			},
			filterActionOptions: ["", "blocked", "allowed"],
			fields: [
				{ key: 'domain', label: 'Domain', class: 'align-middle', search: 'search by domain' },
                { key: 'externalip', label: 'External Ip', class: 'align-middle', search: '' },
				{ key: 'verdict', label: 'Action', class: 'align-middle text-wrap', search: 'search by action' },
				{ key: 'timestamp', label: 'Date & Time', class: 'align-middle text-wrap', search: '' },
			],
			height: 300,
			topDestinations: [],
			allowed: {},
			blocked: {}
        }
    },
    components: {
		ITCSpinner,
	},
    computed: {
		filteredActivity() {
			if (!this.searchText) return this.activity;
			return this.activity.filter(item => {
				return item.domain.toLowerCase().includes(this.searchText.toLowerCase()) || item.externalip.toString().includes(this.searchText.toString());
			});
		}
	},
    methods: {
		processGraph(data, type) {
			var aseries = [];
			var appnames = [];

			for(let i=0; i<data.length; i++) {
				if(data[i].counts[type]>0) {
					appnames.push(data[i].domain);
					aseries.push(data[i].counts[type]);
				}
			}

			return {
				series: aseries,
				labels: appnames,
				theme: {
					palette: 'palette2',
				},
				chart: {
					dropShadow: {
						enabled: true,
						color: '#111',
						top: -1,
						left: 3,
						blur: 3,
						opacity: 0.2,
					},
				},
				plotOptions: {
					pie: {
						offsetY: 25,
						customScale: 0.9,
						startAngle: -90,
						endAngle: 270,
					},
					labels: {
						show: true,
						total: {
							show: true,
							label: 'Applications',
						},
					},
				},
				tooltip: {
					y: {
						formatter: function(val) {
							return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						},
					},
				},
				dataLabels: {
					enabled: false,
				},
				legend: {
					formatter: function(val, opts) {
						let count = (opts.w.globals.series[opts.seriesIndex]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						return val + ' - ' + count;
					},
					width: 300,
					position: 'right',
					horizontalAlign: 'right',
				},
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: 'bottom',
							},
						},
					},
				],
			};
		},
		processData() {
			this.allowed = this.processGraph(this.topDestinations, "allowedrequests");
			this.blocked = this.processGraph(this.topDestinations, "blockedrequests");
		},
        getActivity() {
            this.loaded.list = false;
            let search = this.filters;
            this.$http.post(`ciscoumbrella/activity/${this.source}`, search).then((res) => {
                this.activity = res.data.data[0].data;
                if(this.activity.length>0 && this.activity[0].identities[0].label) {
                    this.title = ' - '+this.activity[0].identities[0].label;
                }
                this.loaded.list = true;
            }).catch((err)=>{
                console.log('Error (ciscoumbrella/activity):', err)
            })
        },
		getTopDestinations() {
			this.loaded.chart = false;
			this.$http.get(`ciscoumbrella/top-destinations/${this.source}`).then((res) => {
				this.topDestinations = res.data.data[0].data;
				this.processData();
                this.loaded.chart = true;
            }).catch((err)=>{
                console.log('Error (ciscoumbrella/top-destinations):', err)
            })
		},
        timestampToDate(t) {
            let date = new Date(t);
            return date;
        }
    },
    mounted() {
		this.getActivity();
		this.getTopDestinations();
	},
	created() {
		
	}
}
</script>

<style scoped>
.hidden-header {
	display: none;
}
.search-box {
	position: relative;
}
.search-bar {
	padding-left: 30px;
}
.search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}
.footer {
	padding: 15px 30px 0px;
}
.pagination {
	float: right;
}
.block {
    color: red;
}
.allowed {
    color: green;
}
</style>
